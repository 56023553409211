<template>
  <section
    class="sale-office-root"
    :class="$customSectionClasses(props.cfgs)"
  >
    <div :class="{'container': $viewport.isGreaterOrEquals('sm')}">
      <Map
        v-if="officePoints && officePoints.length"
        :office-points="officePoints"
        :projects="projectsList || []"
        :active-project="activeProject"
        :custom-height="true"
      />
    </div>
  </section>
</template>

<script setup lang="ts">
import type { IBlockDefaultProps } from '#sitis/internal/models/common';
import type { SectionTypes } from '~/plugins/customSectionClasses';
import { type Ref, ref } from '#imports';
import type { MapPoint } from '~/components/map/Map.vue';
import { catalogStore as useCatalogStore } from '#sitis/stores/modules/catalog-store';
import { appConfigStore as useAppConfigStore } from '#sitis/stores/modules/app-store';
import { storeToRefs } from 'pinia';
import { getSalesOfficePoint } from "~/composables/useOffices";

const catalogStore = useCatalogStore();
const appConfigStore = useAppConfigStore();

const { catalogTree } = storeToRefs(catalogStore);
const { vars, config } = storeToRefs(appConfigStore);
const $viewport = useViewport();

type MapWithDotsItemType = {
  lat: string;
  lon: string;
  subtitle: string;
  title: string;
}

type MapWithDotsType = Omit<IBlockDefaultProps, 'values' | 'cfgs'> & {
  values: {
    center_lat: string;
    center_lon: string;
    items: MapWithDotsItemType[];
  };
  cfgs: SectionTypes;
}

const { $customSectionClasses, $api } = useNuxtApp();
const props = defineProps<MapWithDotsType>();

const officePoints: Ref<MapPoint[]> = ref([]);
const projectsList = catalogTree?.value[0]?.items;
const activeProject: Ref<{ id: string | number }> = ref({ id: '' });

onMounted(async () => {
  await getSalesOfficePoint(officePoints);
});
</script>
